import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';

const BookAnEvent = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container>
        <main>
        <h1>Book an Event</h1>
        <p>To book an event, please email us at <a href="mailto:islandinkla@gmail.com">islandinkla@gmail.com</a>.</p>
        <p>In your email, please include:</p>
        <ul>
            <li>Date of event</li>
            <li>Type of event</li>
            <li>Number of guests</li>
        </ul>
        <p>We will contact you within 24 hours with a custom quote.</p>
        </main>
        </Container>
    )
}

export default BookAnEvent