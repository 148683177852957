import React, { useState, useCallback, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from "react-images";

const PhotoGallery = props => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  let photos = []

  let i = 1;
  while (i < 45) {
    photos.push({
      src: `https://islandinkla.com/img/iil_${i}.jpg`,
      width: 4,
      height: 3
    })
    i++;
  }






  return (
    <Container>
      <main id="pageNotFound">
        <h1>Photo Gallery</h1>
        <Gallery photos={photos} onClick={openLightbox} direction={"column"} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </main>
    </Container>
  )
}

export default PhotoGallery