import React from 'react'
import Container from 'react-bootstrap/Container';

const Footer = props => {

    return (
        <footer>
        <Container>
       
       <p>(818) 681-7454<br />(310) 463-6595</p>
       <p><a href="mailto:islandinkla@gmail.com">islandinkla@gmail.com</a></p>

    <p>&copy; {new Date().getFullYear()} Island Ink L.A. All Rights Reserved.</p>
        </Container>
         </footer>
    )
}

export default Footer