import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';

const NotFound = props => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <main id="pageNotFound">


        <h1>Professional Airbrush Body Art and Temporary Tattoos</h1>
        <p>Island Ink L.A. is your top source for professional airbrush body art and temporary tattoos. Based in Southern California, our goal is to make your event fun and memorable. Whether your event is a Bar-Bat Mitzvah, corporate/private party, birthday, grad night or fundraiser, our custom designs are age/event appropriate, and extremely popular.</p>
        <strong>All our tattoos:</strong>
        <ul>
          <li>Are temporary &amp; safe
    <ul>
              <li>FDA approved ingredients</li>
              <li>Hypo-allergenic &amp; cosmetically formulated body paint</li>
              <li>Alcohol based, contain NO LEAD</li>
            </ul>
          </li>
          <li>Painless &amp; waterproof</li>
          <li>Dry instantly &amp; don't smudge</li>
          <li>Last 3 to 7 days</li>
          <li>Easily removed with rubbing alcohol or baby oil</li>
        </ul>

        <p>We offer our professional services for events on an hourly, per artist basis and have hundreds of custom tattoo stencils to choose from. We can also create a unique tattoo upon request for your special event. Our custom designs are extremely popular among children, teens and adults alike.</p>
        <p>Please contact us today for your special event!</p>
      </main>
    </Container>
  )
}

export default NotFound