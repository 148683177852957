import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';

const Contact = props => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container>
        <main>
        <h1>Contact</h1>
        <p>Fred &amp; Kristi Descher<br />
(818) 681-7454<br />
(310) 463-6595<br />
<a href="mailto:islandinkla@gmail.com">islandinkla@gmail.com</a>

</p>
        </main>
        </Container>
    )
}

export default Contact