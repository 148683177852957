import React from 'react'
import Navbar from 'react-bootstrap/Navbar'

import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';

import logo from './logo-1.jpg';

const MainNavBar = props => {

    return (
        <header>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
                <Container>
                    <Navbar.Brand href="#home">
                    <LinkContainer to="/home"><img id="navLogo" src={logo} alt="Island Ink LA" className="img-fluid" /></LinkContainer>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto"></Nav>
                        <Nav>
                            <LinkContainer to="/photo-gallery">
                                <Nav.Link href="#">Photo Gallery</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/book-an-event">
                                <Nav.Link href="#">Book an Event</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/about">
                                <Nav.Link href="#">About</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/faq">
                                <Nav.Link href="#">FAQ</Nav.Link>
                            </LinkContainer>

                        </Nav>

                    </Navbar.Collapse></Container>
            </Navbar>

        </header>

    )
}

export default MainNavBar