import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';

const About = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container>
            <main id="pageNotFound">
                <h1>About</h1>
                <p>Island Ink L.A.was founded in 2008 by Fred and Kristi Descher. Born in Honolulu, Hawaii, Fred was always facinated by traditional Hawaiian tattoos, art and culture. After a trip to the islands, they decided to bring a piece of Hawaii back to Southern California. With their mutual interest in special events and tribal body art, they created Island Ink L.A.</p>

                <p>Receiving immediate recognition of their tribal designs, they began creating their own unique tattoo stencils. Since their work is custom, you will not find their portfolio anywhere else.</p>
                <p>They specialize in Bar/Bat Mitzvahs, birthday and Sweet 16s, company events, grad nights, private parties and fundraisers.</p>

                <p>New parents, Fred and Kristi enjoy working with kids and volunteer their services for various childrens charities and events year-round.</p>
            </main>
        </Container>
    )
}

export default About