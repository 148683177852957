import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';

const Landing = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container>
            <main id="landingPage">
                <div className="row">
                    <div className="col-md-6"><a href='https://islandinkla.squarespace.com/'><img className="img-fluid" src="https://islandinkla.com/img/landing-left.jpg" alt="Lustre Metallic Tattoos" /></a></div>
                    <div className="col-md-6"><Link to='/home'><img className="img-fluid" src="https://islandinkla.com/img/landing-right.jpg" alt="Custom Airbrush Tattoo Services" /></Link></div>
                </div>
            </main>
        </Container>
    )
}

export default Landing