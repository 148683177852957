module.exports = [

    { old: 'index.php', new: '' },
    { old: 'about_us.php', new: 'about' },
    { old: 'photo_gallery.php', new: 'photo-gallery' },
    { old: 'faqs.php', new: 'faq' },
    { old: 'contact.php', new: 'contact' },
    { old: 'book_an_event.php', new: 'book-an-event' },
    


]