import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';

const FAQ = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container>
            <main id="pageNotFound">
                <h1>FAQ</h1>
                <p><strong>Is this henna?</strong></p>
                <p>No, airbrush body paint is not henna. Henna penetrates and stains the top two layers of skin with an orange/brown tint and can last between 1-4 weeks. It can be very messy, staining carpeting and smudging while waiting for the application to dry. Drying times vary, anywhere from 20 minutes to several hours.</p>
                    
                    <p>Airbrush body paint is very different. It is topical, quick drying and easily removed if necessary with rubbing alcohol. It does not penetrate the layers of skin as henna does, and comes in a wide variety of colors. Although a terrific art form, natural henna or mehndi is very different from airbrush body paint.</p>
                <p><strong>What is "Black Henna"?</strong></p>
                <p>Para-phenylenediamine (PPD) often called &ldquo;black henna&rdquo; is a synthetic black hair dye that is illegal to apply to skin. It can cause blistering, open sores, scarring, and lead to lifelong health problems. Popular in Mexico and beach tourist destinations, Black Henna is EXTREMELY dangerous and should be avoided at all costs. </p>
                <p><strong>How long does an airbrush tattoo last?</strong></p>
                <p>Typically, tattoos last anywhere from <strong>three</strong> to <strong>seven</strong> days, depending on several factors. The most important factor is where the tattoo is located. If it will rub against any surface such as tight clothing, it will start to come off fairly quickly. Also, if the tattoo is in an area where you tend to sweat, it will also quickly fade. Longest lasting areas are on the ankle/calf/leg, or upper arm/shoulder. Ankle tattoos have been known to last up to three weeks if cared for well. </p>
                <p><strong>What is the best way to care for my new tattoo?</strong></p>
                <p>Other than placement and skin type, the best way to keep your tattoo looking great is to avoid touching it as much as possible. Since they are topical, they will eventually rub off or naturally exfoliate.</p><p>You should also avoid applying lotions or cremes on the area, as they tend to remove the tattoo. Since they are waterproof, showering will not affect them, as long as you don't rub them too much.</p>
            </main>
        </Container>
    )
}

export default FAQ