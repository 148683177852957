import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import MainNavBar from './components/MainNavBar'
import NotFound from './components/NotFound'
import Home from './components/Home'
import Landing from './components/Landing'
import About from './components/About'
import FAQ from './components/FAQ'
import PhotoGallery from './components/PhotoGallery'
import BookAnEvent from './components/BookAnEvent'
import Contact from './components/Contact'
import Footer from './components/Footer'

import redirects from './data/redirects'

function App() {
  return (
    <React.Fragment>
      <Router>

        <MainNavBar />
        <Switch>
        {redirects.map(redirect =>< Redirect key = "{redirect.old}" from = {
              `/${redirect.old}`
            }
            to = {
              `/${redirect.new}`
            } />)}
          <Route path="/about" component={About} />
          <Route path="/faq" component={FAQ} />
          <Route path="/book-an-event" component={BookAnEvent} />
          <Route path="/photo-gallery" component={PhotoGallery} />
          <Route path="/contact" component={Contact} />
          <Route path="/home" component={Home} />
          <Route path="/" component={Landing} />
          <Route path="*" component={NotFound} />
        </Switch>

      <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
